import "../Styles/Styles.css";
function GetFooter() {
  return (
    <footer className="footer">
      <p>Urban Unwind Massage </p>
      <p>Copyright © 2022 by Urban Unwind Massage</p>
    </footer>
  );
}

export default GetFooter;
